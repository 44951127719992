import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { polyfill } from "seamless-scroll-polyfill";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Setup from "./helpers/Setup";
import CustomerHelper from "./helpers/CustomerHelper";
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import Vue3Lottie from 'vue3-lottie';

/**
 * AppWrapper
 * This loads the data and starts the app
 */
class AppWrapper {

    /**
     * initApp
     * Initializes the app
     */
    static async init() {
        polyfill();
        await Setup.getDataFromApi();
        AppWrapper.createViewApp();
    }

    /**
     * createViewApp
     * Creates the view app
     */
    static createViewApp() {

        // Create app
        CustomerHelper.initApp();
        const app = createApp(App);
        app.use(VueSvgInlinePlugin);
        app.use(Vue3Lottie);
        
        // Set base
        app.use(router);
        app.mount("#app");

        // Force first page        
        if (window.location.pathname!='/') {
            // router.replace("/").catch(e => console.error(e));
        }

        // Set listener for page focus, set full-screen if true
        CustomerHelper.handleAsyncRequest({type:'toggleFullScreen'});
        document.addEventListener("visibilitychange", async () => {
            CustomerHelper.handleAsyncRequest({type:'toggleFullScreen'});
        });

        // Load sentry if set in environment
        if (process.env.VUE_APP_SENTRY_DSN) {
            Sentry.init({
                dsn: process.env.VUE_APP_SENTRY_DSN,
                integrations: [
                    new Integrations.BrowserTracing()
                ],
                tracesSampleRate: 1
            });

            // Sentry - Vue error capturing
            app.config.errorHandler = ((err, _, info) => {
                Sentry.setTag("info", info);
                Sentry.captureException(err);
                console.error(err);
            });
        }

    }
}
AppWrapper.init();