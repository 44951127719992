import { PageName } from "@/utils/Constants";
import { ISaveFile } from "@/interfaces/ISaveFile";
import apiModule from "@/helpers/APIModule";
import Setup from "./Setup";

/**
 * Helper
 * General helper file
 */
export default class FlowHelper {
    public static state: any = {
    };

    /**
     * Get next page in flow
     * @param name 
     */
    static getNextFlowPage(name: PageName): PageName {

        // Show walkthrough after setup in case it's available
        const views: any = Setup.getValue("settings.flow.views.value",[]);
        if (name == PageName.SETUP && views && views.includes('walkthrough') && !this.getPageShown(PageName.WALKTHROUGH)) {
            return PageName.WALKTHROUGH;
        }
        // Splash screen
        else if (name == PageName.SETUP && views && views.includes('splash')) {
            return PageName.SPLASH;
        }
        // Show terms and conditions after walkthrough
        else if ((name == PageName.SPLASH || name == PageName.WALKTHROUGH) && !localStorage.getItem('termsConditions' + Setup.getGameId()) && views.includes('termsConditions')) {
            return PageName.TERMSCONDITIONS;
        }
        // We have the setup page
        else if ((name == PageName.SPLASH || name == PageName.SETUP || name == PageName.WALKTHROUGH || name == PageName.TERMSCONDITIONS) && apiModule.isLoggedIn()) {
            return PageName.LAUNCH;
        }
        // Go to register if needed
        else if ((name == PageName.SPLASH || name == PageName.SETUP || name == PageName.WALKTHROUGH || name == PageName.TERMSCONDITIONS) && apiModule.getRegisterFlow()) {
            return PageName.REGISTER;
        }
        else if (name == PageName.SPLASH || name == PageName.SETUP || name == PageName.WALKTHROUGH || name == PageName.TERMSCONDITIONS) {
            return PageName.LOGIN;
        }
        else if (name==PageName.LAUNCH) {
            // Check if we have a launch video and go there first
            if (Setup.includesView('launchVideo')) {
                return PageName.LAUNCHVIDEO;
            }
            else if (Setup.includesView('storytelling')) {
                return PageName.STORYTELLING;
            }
            // Check if we need to go to character selection
            else if (Setup.includesView('characterSelection')) {
                return PageName.CHARACTERSELECTION;
            }
            // Check if we need to go to character selection
            else {
                return PageName.GAME;
            }
        }
        // Start the game after the launch video or storytelling
        else if (name== PageName.LAUNCHVIDEO || name==PageName.STORYTELLING) {
            if (Setup.includesView('characterSelection')) {
                return PageName.CHARACTERSELECTION;
            }
            else {
                return PageName.GAME;
            }
        }

        return PageName.SETUP;
    }

    /**
     * Get next flow button
     * @param followFlow 
     * @param currentName 
     * @param targetName 
     */
    static getNextFlowButton(followFlow: boolean, currentName: PageName, targetName?: PageName): PageName {
        if (followFlow) {
            const nextPage = FlowHelper.getNextFlowPage(currentName);
            return nextPage ? nextPage : currentName;
        }
        else if (targetName) {
            return targetName;
        }
        return PageName.SETUP;
    }

    /**
     * Set current shown page
     * @param name 
     */
    static setPageShown(name: PageName): void {
        let saveData: any = FlowHelper.getSaveData();
        const key = `shown_${name}`;
        if (typeof saveData === "string") saveData = JSON.parse(saveData)
        saveData.ui[key] = true;

        // Sync data to server
        FlowHelper.updateSaveData(saveData);
    }

    /**
     * Get current shown UI
     * @param name 
     */
    static getPageShown(name: PageName): boolean {
        const saveData = FlowHelper.getSaveData();
        const key = `shown_${name}`;
        if (saveData.ui && saveData.ui[key]) return saveData.ui[key]
        else return false
    }

    /**
     * Update data in local storage
     * @param data 
     * @param omitServerSync 
     */
    static updateSaveData(data: ISaveFile) {
        apiModule.updatePlayer({ saveData: data }).then(() => console.log("save data synced"));
    }

    /**
     * Get data from local storage
     */
    static getSaveData(): ISaveFile {

        const data: any = apiModule.getPlayerData();
        if (!data || !data.saveData) {
            return {
                game: {
                    overall:{
                        totalScore: 0,
                        highscore: 0
                    },
                    maps: []
                },
                ui : {}
            };
        }
        return data.saveData;

    }

    /**
     * Get link to other page
     * @param pageName 
     */
    static getPageLink(pageName: PageName): string {
        return `/${pageName}`;
    }

    /**
     * Get state
     */
    static getState(name: string): any {
        return this.state[name];
    }

    /**
     * Set state
     */
    static setState(name: string, value: any): any {
        this.state[name] = value;
    }

}