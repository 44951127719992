import Setup from "./Setup";
import Helper from '@/helpers/CommonFunctions';

export default class GameHolder {
    static game: any;
    static width: any;
    static height: any;

    /**
     * Create a new game instance
     * @param parentWidth 
     * @param parentHeight 
     */
    static async loadInstance(parentWidth?: number, parentHeight?: number) {
        let Game: any;

        if (!this.game) {
            // Pacman
            if (Setup.getValue("settings.gameType") == 'pacman') {
                Game = await import("@/game/pacman/game");
            }
            // Scrolling shooter
            else if (Setup.getValue("settings.gameType") == 'scrollingshooter') {
                Game = await import("@/game/scrollingshooter/game");
            }
            // climber 
            else if (Setup.getValue("settings.gameType") == 'climber') {
                Game = await import("@/game/climber/game");
            }
            // serving
            else if (Setup.getValue("settings.gameType") == 'serving') {
                Game = await import("@/game/serving/game");
            } 
            // slicer
            else if (Setup.getValue("settings.gameType") == "slicer") {
                Game = await import("@/game/slicer/game");
            }
            // freekick
            else if (Setup.getValue("settings.gameType") == "freekick") {
                Game = await import("@/game/freekick/game");
            }   
            // breakout
            else if (Setup.getValue("settings.gameType") == "breakout") {
                Game = await import("@/game/breakout/game");
            }                    
            // keepingitup
            else if (Setup.getValue("settings.gameType") == "keepingitup") {
                Game = await import("@/game/keepingitup/game");
            }                                    

            // Fallback to match 3
            else {
                Game = await import("@/game/match3/game");
            }

            this.game = new Game.default(parentWidth || 0, parentHeight || 0);
            
            try {
                this.game.gameInstance.sound.setMute(Setup.isMuted());
            }
            catch (e) {
                console.log("Cannot set muted sound");
            }
        }
        this.width = parentWidth;
        this.height = parentHeight;
        return this.game;
    }

    /**
     * Resize the game
     * @param parentWidth 
     * @param parentHeight 
     */
    static async resize(parentWidth?: number, parentHeight?: number) {
        // Destroy old
        this.game.destroy();
        await Helper.sleep(500);
        this.game = undefined;
        // Create new
        this.loadInstance(parentWidth, parentHeight);
        this.width = parentWidth;
        this.height = parentHeight;
    }

    /**
     * Get active game instance
     */
    static getInstance() {
        return this.game;
    }

    /**
     * Check whether there is an active instance
     */
    static hasInstance(): boolean {
        return !!this.game;
    }

    /**
     * Check whether currently muted
     */
    static isMuted(): boolean {
        if (this.game) {
            return false;
        }
        return this.game.gameInstance.sound.mute;
    }

    /**
     * Set muted
     */
    static setMuted(muted: boolean) {
        if (this.game && this.game.gameInstance) {
            this.game.gameInstance.sound.setMute(muted);
        }
    }

}