import { defineComponent } from "vue";
import Setup from "../helpers/Setup";
import Flow from '../helpers/Flow';

/**
 * Mixin for components
 * This is used to add helpers to standard components
 */
export const configMixin = defineComponent({
    methods: {
        getCopy: Setup.getCopy,
        getImage: Setup.getImage,
        getValue: Setup.getValue,
        getCustomCSS: Setup.getCustomCSS,
        getNextFlowButton: Flow.getNextFlowButton,
        getNextFlowPage: Flow.getNextFlowPage,
        setPageShown: Flow.setPageShown,
        getPageShown: Flow.getPageShown,
        getPageLink: Flow.getPageLink
    }
});