import { PageName } from "@/utils/Constants";
import LaunchView from "@/views/LaunchView.vue";
import LeaderboardView from "@/views/LeaderboardView.vue";
import LoginView from "@/views/LoginView.vue";
import PasswordRequestView from "@/views/PasswordRequestView.vue";
import PasswordResetView from "@/views/PasswordResetView.vue";
import RegisterVerifyView from "@/views/RegisterVerifyView.vue";
import RegisterView from "@/views/RegisterView.vue";
import TermsConditionsView from "@/views/TermsConditionsView.vue";
import CharacterSelectionView from "@/views/CharacterSelectionView.vue";
import SplashView from "@/views/SplashView.vue";
import LaunchVideoView from "@/views/LaunchVideoView.vue";
import StorytellingView from "@/views/StorytellingView.vue";
import GameView from "@/views/GameView.vue";
import ScoreView from "@/views/ScoreView.vue";
import SettingsView from "@/views/SettingsView.vue";
import SetupView from "@/views/SetupView.vue";
import StoreDetailView from "@/views/StoreDetailView.vue";
import StoreView from "@/views/StoreView.vue";
import WalkthroughView from "@/views/WalkthroughView.vue";
import GameMapView from "@/views/GameMapView.vue";
import CompleteDetailsView from "@/views/CompleteDetailsView.vue";
import { createRouter, createWebHistory, RouteLocation, RouteRecordRaw } from "vue-router";

// JSON to props parser
const propsParser = (route: RouteLocation) => {
    const rawData = route.params["data"] || route.query["data"] || null;
    if (rawData) {
        const parsedData = JSON.parse(rawData as string);
        return {
            routeData: parsedData
        }
    }
};

// Generate routes
const pages = [
    "setup",
    "splash",
    "launch",
    "launchVideo",
    "storytelling",
    "login",
    "register",
    "walkthrough",
    "termsConditions",
    "leaderboard",
    "score",
    "leaderboard",
    "characterSelection",
    "game",
    "walkthrough",
    "register",
    "settings",
    "passwordRequest",
    "passwordReset",
    "gameMap",
    "completeDetails"
];

const routes: RouteRecordRaw[] = pages.map(page => {

    // Set base route
    const route: RouteRecordRaw | any = {
        name: page,
        path: `/${page}`,
        ...(page === pages[0] ? { alias: "/" } : {})
    };

    // Add components and other settings to route, based on name
    switch (page) {
        case PageName.SETUP:
            route.component = SetupView;
            break;
        case PageName.SPLASH:
            route.component = SplashView;
            break;
        case PageName.WALKTHROUGH:
            route.component = WalkthroughView;
            break;
        case PageName.LOGIN:
            route.component = LoginView;
            break;
        case PageName.PASSWORD_REQUEST:
            route.component = PasswordRequestView;
            break;
        case PageName.PASSWORD_RESET:
            route.component = PasswordResetView;
            break;
        case PageName.TERMSCONDITIONS:
            route.component = TermsConditionsView;
            break;
        case PageName.REGISTER:
            route.component = RegisterView;
            break;
        case PageName.REGISTER_VERIFY:
            route.component = RegisterVerifyView;
            route.props = propsParser;
            break;
        case PageName.LAUNCH:
            route.component = LaunchView;
            route.props = propsParser;
            break;
        case PageName.LAUNCHVIDEO:
            route.component = LaunchVideoView;
            route.props = propsParser;
            break;
        case PageName.STORYTELLING:
            route.component = StorytellingView;
            route.props = propsParser;
            break;
        case PageName.SETTINGS:
            route.component = SettingsView;
            break;
        case PageName.LEADERBOARD:
            route.component = LeaderboardView;
            break;
        case PageName.CHARACTERSELECTION:
            route.component = CharacterSelectionView;
            route.props = propsParser;
            break;
        case PageName.GAME:
            route.component = GameView;
            route.props = propsParser;
            break;
        case PageName.SCORE:
            route.component = ScoreView;
            route.props = propsParser;
            break;
        case PageName.STORE:
            route.component = StoreView;
            break;
        case PageName.STORE_DETAIL:
            route.component = StoreDetailView;
            route.props = propsParser;
            break;
        case PageName.GAME_MAP:
            route.component = GameMapView;
            route.props = propsParser;
            break;
        case PageName.COMPLETE_DETAILS:
            route.component = CompleteDetailsView;
            route.props = propsParser;
            break;
    }

    // Return route entry
    return route;

}).filter(route => route !== null);

// Create router
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes
});
export default router;