//Supported page names
export enum PageName {
    SETUP = "setup",
    SPLASH = "splash",
    WALKTHROUGH = "walkthrough",
    TERMSCONDITIONS = "termsConditions",
    LOGIN = "login",
    REGISTER = "register",
    REGISTER_VERIFY = "registerVerify",
    PASSWORD_RESET = "passwordReset",
    PASSWORD_REQUEST = "passwordRequest",
    LAUNCH = "launch",
    LAUNCHVIDEO = "launchVideo",
    STORYTELLING = "storytelling",
    SETTINGS = "settings",
    CHARACTERSELECTION="characterSelection",
    GAME = "game",
    SCORE = "score",
    LEADERBOARD = "leaderboard",
    STORE = "store",
    STORE_DETAIL = "storeDetail",
    GAME_MAP = "gameMap",
    COMPLETE_DETAILS = "completeDetails"
}

// Dialog types
export enum DialogName {
    CHECK_PRIZE = "checkPrize",
    GAME_OVER = "gameOver",
    HINT = "hint"
}

export enum DialogAction {
    CLOSE = "close",
    CONFIRM = "confirm",
    DENY = "deny"
}