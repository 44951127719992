import Setup from "./Setup";

export default class BackgroundHelper {

    static setBackground(view:string) {
        const background=Setup.getValue("view."+view+".backgroundOption");

        if (background) {
            const event = new CustomEvent("setBackground", { detail: { background: background } } as any);
            document.dispatchEvent(event);
        }
        return background;
    }   

}