import apiModule from "@/helpers/APIModule";
import { IPlayer, IPlayerResponse } from "@/interfaces/api/IPlayer";
import { ISaveFile } from "@/interfaces/ISaveFile";

class GlobalSaveFile {
  create() {
    const saveData: ISaveFile = {
      game: {
        overall: {
          totalScore: 0,
          highscore: 0
        },
        maps: []
      },
      ui: {}
    }
    this.update(saveData);

    return saveData;
  }

  get(): ISaveFile {
    const playerData = apiModule.getPlayerData() as (IPlayer & IPlayerResponse);
    return playerData && playerData.saveData ? playerData.saveData : this.create();
  }

  update(saveData: ISaveFile) {
    apiModule.updatePlayer({ saveData: saveData });
  }

  getHighscore(mapID?: number, levelID?: number) {
    const saveFile = this.get();
    let highscore: number;

    if (mapID) {
      const map = saveFile.game.maps.find((map: any) => map.id === mapID);
      const level = map?.levels.find((level: any) => level.id === levelID);
      highscore = level ? level.highscore : 0;
    } else {
      highscore = saveFile.game.overall.highscore;
    }

    return highscore;
  }

  getMap(mapID: number) {
    const saveFile = this.get();
    const map = saveFile.game.maps.find(map => map.id === mapID);
    return map;
  }

  getLatestLevelInMap(mapID: number) {
    const map = this.getMap(mapID);
    const levelList = map?.levels.map((level: any) => level.id) || [];

    return levelList.length ? Math.max(...levelList) : 1;
  }

  getNextLevelInMap(mapID: number) {
    const map = this.getMap(mapID);
    if (!map?.levels.length) {
      return 1;
    }

    const currentLevel = this.getLatestLevelInMap(mapID);
    const nextLevel = currentLevel + 1;
    return nextLevel > (map?.totalLevels || 0) ? currentLevel : nextLevel;
  }

  getLevel(mapID: number, levelID: number) {
    const map = this.getMap(mapID);
    const level = map?.levels.find(level => level.id === levelID);
    return level;
  }

  addMap(mapID: number, totalLevels: number) {
    const saveFile = this.get();

    if (saveFile.game.maps && !saveFile.game.maps.find((map: any) => map.id === mapID)) {
      saveFile.game.maps.push({
        id: mapID,
        totalStars: 0,
        totalScore: 0,
        totalLevels: totalLevels,
        levels: []
      })
      this.update(saveFile);
    }    
  }
}

const globalSaveFile = new GlobalSaveFile();
export default globalSaveFile;