import { IPlayer } from "@/interfaces/api/IPlayer";
import { ITagTracker } from "@/interfaces/ITagTracker";
import Setup from "./Setup";

/**
 * Helper
 * General helper file
 */
export default class Helper {

    /**
     * Sleep for a period of time
     * @param durationMs 
     */
    static async sleep(durationMs: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, durationMs));
    }

    static remapValue(value: number, low1: number, high1: number, low2: number, high2: number) {
        return low2 + (value - low1) * (high2 - low2) / (high1 - low1);
    }

    /**
     * Shuffle an array
     * @param data 
     */
    static shuffleArray<T>(data: T[]): T[] {
        for (let i = data.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [data[i], data[j]] = [data[j], data[i]];
        }
        return data;
    }

    /**
     * Get current url of game
     */
    static getBaseUrl(): string {
        return `${window.location.protocol}//${window.location.host}`;
    }

    /**
     * Get datalayer for tag manager
     */
    static getTracker(): ITagTracker | null {
        return "dataLayer" in window ? (window as any)["dataLayer"] : null;
    }

    /**
     * Get date for API
     */
    static getAPIDate(): string {
        const date = new Date();
        return date.toISOString();
    }

    static isFlowForced(): boolean {
        return process.env.VUE_APP_FORCE_FLOW === "true";
    }

    /**
     * Check whether we are running locally
     */
    static isLocal(): boolean {
        return process.env.VUE_APP_ENV === "local";
    }

    /**
     * Form data to IPlayer mapper
     **/
    static mapDataToUser(formData: any): IPlayer {
        const userKeys = ["firstName", "lastName", "email", "terms"];
        const skipKeys = ["password"];

        //Set base for output
        const output: any = {
            language: Setup.getLanguage()
        };

        //Map data
        for (const key of Object.keys(formData)) {
            if (skipKeys.includes(key)) {
                continue;
            }

            //Process keys
            if (userKeys.includes(key)) {
                output[key] = formData[key];
            }
            else {
                if (!("extraData" in output)) {
                    output["extraData"] = {};
                }
                output["extraData"][key] = formData[key];
            }
        }

        //Return casted variant
        return output;
    }

    /**
     * Generate random password
     * @param length 
     */
    static generatePassword() {
        const pick = function (str: string, min: integer, max: integer) {
            let n, chars = '';
            if (typeof max === 'undefined') {
                n = min;
            } else {
                n = min + Math.floor(Math.random() * (max - min + 1));
            }
            for (let i = 0; i < n; i++) {
                chars += str.charAt(Math.floor(Math.random() * str.length));
            }
            return chars;
        }

        const specials = '!@#$%^&*()_+{}';
        const lowercase = 'abcdefghijklmnopqrstuvwxyz';
        const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const numbers = '0123456789';
        const all = specials + lowercase + uppercase + numbers;

        let password = '';
        password += pick(lowercase, 5, 7);
        password += pick(specials, 2, 4);
        password += pick(uppercase, 2, 4);
        password += pick(numbers, 1, 2);
        password += pick(all, 3, 10);
        password = password.split('').sort(function(){return 0.5-Math.random()}).join('');
        return password;
    }
}