import apiModule from "@/helpers/APIModule";
import Helper from "@/helpers/CommonFunctions";
import Setup from "@/helpers/Setup";

interface NativeBridge {
    message(name: string): NativeSystem
}

interface NativeSystem {
    send(data: { [key: string]: any }): void;
    on(name: string, data: any): void;
}

interface NativeUser {
    firstname: string,
    lastname?: string,
    email: string,
    mcdonaldsId: string
}


export default class CustomerHelper {
    private static MAX_ATTEMPT =  20;
    private static WAIT_DELAY = 100;
    private static bridge: NativeBridge | null = null;
    private static bridgeTested = false;

    static initApp() {
        CustomerHelper.init();
    }

    // eslint-disable-next-line
    static toGameOverRedirect() {

    }
    
    /**
     * Static functions
     */
    static supportedRequest(type: string): boolean {
        const types:Array<string>=['claimPrize', ''];
        return types.includes(type);
    }

    // eslint-disable-next-line
    static handleRequest(data: any) {

    }

    static async handleAsyncRequest(data: any): Promise<any> {
        
        // Handle full screen
        if (data.type=='toggleFullScreen') {
            if (!document.hidden) {
                return CustomerHelper.toggleFullScreen();
            }
        }
        // Claim a price
        else if (data.type=='claimPrize') {
            return CustomerHelper.claimPrize(data.prize as any);
        }
        // Login
        else if (data.type=='login') {
            return CustomerHelper.login();
        }
        // Loyalty points
        else if (data.type=='loyaltyPoints') {
            return CustomerHelper.getLoyaltyPoints();
        }
        // Burn loyalty points
        else if (data.type=='burnLoyaltyPoints') {
            return CustomerHelper.burnLoyaltyPoints(data.points);
        }
    }

    static getLink(link: string) {
        return { link: `gmalite://gmalite-smartweb?weburl=${link}`, target: '_self' };
    }

    static getDefaultUrlSuffix(): string {
        return "openOutsideMcd=true";
    }

    /**
     * Native Bridge modules
     */
    static init() {
        document.addEventListener("mcdBridgeReady", () => {
            CustomerHelper.bridge = CustomerHelper.getBridge();
        });
    }

    /**
     * Handle login using native methods
     */
    static async login() {
        // Try to get user info
        let user = await CustomerHelper.getUser();

        // If no user found, trigger login
        if (!user) {
            user = await CustomerHelper.triggerLogin();

            // If still no user, show alert and stop loading
            if (!user) {
                alert("User login required to continue! Please close/open game again if you want to continue.");
                return;
            }
        }

        // Get & set language
        await CustomerHelper.getUserLanguage();

        // Sign in user
        await apiModule.registerPlayer({
            firstName: user.firstname,
            lastName: user.lastname,
            email: user.email,
            terms: true,
            password: user.mcdonaldsId
        });

        // Enable fullscreen
        await CustomerHelper.toggleFullScreen();

    }

    /**
     * Get User
     * Requests the current user from the bridge
     */
    static async getUser() {
        const bridge = await CustomerHelper.waitForBridge();

        //Construct promise
        return new Promise<NativeUser | null>(resolve => {
            const handle = bridge?.message("user");
            if (!handle) {
                return resolve(CustomerHelper.getFallbackUser());
            }

            //Send instruction & process
            handle.send({ getuser: true });
            handle.on("data", (data: NativeUser) => resolve(data));

            //Handle error
            handle.on("error", (e: Event) => {
                console.error("Native user get error:", e);
                resolve(null);
            });
        });
    }

    /**
     * Get language of the user
     */
    static async getUserLanguage() {
        const bridge = await CustomerHelper.waitForBridge();

        // Retrieve language
        return new Promise<void>(resolve => {
            const handle = bridge?.message("system");
            if (!handle) {
                return resolve();
            }

            //Send instruction & process
            handle.send({ "getSelectedLanguage": true });
            handle.on("data", (data: any) => {
                const languageFromBridge = data.language.split("-")[0];
                const languages=Setup.getValue("settings.languages");
                for (const language in languages) {
                    if (languageFromBridge && language && language.toUpperCase()==languageFromBridge.toUpperCase()) {
                        Setup.setLanguage(language);
                    }
                }
                resolve();
            });
        });
    }

    /**
     * Get loyalty points of current user from the bridge
     */
    static async getLoyaltyPoints(): Promise<any> {
        const bridge = await CustomerHelper.waitForBridge();

        // Retrieve points
        return new Promise<number>(resolve => {
            const handle = bridge?.message("deals");
            if (!handle) {
                return resolve(100);
            }

            //Send instruction & process
            handle.send({ "getPoints": true });
            handle.on("data", (data: any) => resolve("points" in data ? parseInt(data["points"]) : 0));

            //Handle error
            handle.on("error", (e: Event) => {
                console.error("Native user get loyalty points error:", e);
                resolve(0);
            });
        });
    }

    /**
     * Prompt login from the user
     */
    static async triggerLogin() {
        const bridge = await CustomerHelper.waitForBridge();

        //Attempt login prompt
        return new Promise<NativeUser | null>(resolve => {
            const handle = bridge?.message("user");
            handle?.send({ "promptlogin": true });
            handle?.on("data", (data: NativeUser) => resolve(data));

            //Handle error
            handle?.on("error", (e: Event) => {
                console.error("Native user sign-in error:", e);
                resolve(null);
            });
        });
    }

    /**
     * Toggle full screen view
     * @param enabled 
     */
    static async toggleFullScreen(enabled = true) {
        await Helper.sleep(100);
        const bridge = await CustomerHelper.waitForBridge();

        //Attempt setting fullscreen
        return new Promise<void>(resolve => {
            const handle = bridge?.message("system");
            handle?.send({ "fullscreen": enabled });
            return resolve();
        });
    }


    /**
     * Claim loyalty coupont
     * @param id the id of the coupon
     */
    static async claimPrize(prize: any) {
        const bridge = await CustomerHelper.waitForBridge();

        // Attempt to claim coupon
        return new Promise<void>((resolve, reject) => {
            const handle = bridge?.message("offerActivation");

            if (!handle) {
                return resolve();
            }
            console.log('in claimPrize mcd', prize);
            
            // Handle
            handle.send({ loyaltyId: prize.campaignId, rewardId: prize.code, autoActivate: false });
            handle.on("error", (e: Event) => {
                reject(e)
            });
            handle.on("done", () => {
                resolve()
            });
        });
    }

    /**
     * Burn loyalty point of the current user
     * @param amount 
     */
    static async burnLoyaltyPoints(amount: number) {
        const bridge = await CustomerHelper.waitForBridge();

        //Attempt burning points
        return new Promise<void>((resolve, reject) => {
            const handle = bridge?.message("deals");
            if (!handle) {
                return resolve();
            }

            //Handle
            handle.send({ "burnPoints": true, points: -amount });
            handle.on("error", (e: Event) => {
                reject(e)
            });
            handle.on("done", () => resolve());
        });
    }

    /**
     * Check whether this is a native user
     */
    static async isNative(): Promise<boolean> {
        const bridge = await CustomerHelper.waitForBridge();
        return bridge !== null;
    }

    /**
     * Wait for the bridge to be completed
     */
    static async waitForBridge() {
        let counter = 0;

        // Try to find bridge ASAP
        while (!CustomerHelper.bridge && counter !== CustomerHelper.MAX_ATTEMPT) {
            await Helper.sleep(CustomerHelper.WAIT_DELAY);
            
            const windowAny = window as any;
            if ("mcd" in windowAny && "bridge" in windowAny["mcd"]) {
                CustomerHelper.bridge=windowAny["mcd"]["bridge"] as NativeBridge;
            }
            counter++;
        }

        // Warn if bridge isn't available
        if (!CustomerHelper.bridge && !CustomerHelper.bridgeTested) {
            console.warn("Native bridge not available, using fallback behaviour!");
            CustomerHelper.bridgeTested = true;
        }
        return CustomerHelper.bridge;
    }

    /**
     * Get Bridge from the window
     */
    private static getBridge() {
        const windowAny = window as any;
        if ("mcd" in windowAny && "bridge" in windowAny["mcd"]) {
            return windowAny["mcd"]["bridge"] as NativeBridge;
        }
        return null;
    }

    /**
     * Get fallback user
     */
    private static getFallbackUser(): NativeUser {
        return {
            firstname: process.env.VUE_APP_DEFAULT_FIRST_NAME || "Test",
            lastname: process.env.VUE_APP_DEFAULT_LAST_NAME || "User",
            email: process.env.VUE_APP_DEFAULT_EMAIL || "test@test.nl",
            mcdonaldsId: "mcdonaldsid77818768GHJkhgkhfjtyfu76ytfjHFythnfTfyjtjhgfit687o7yIUGI&6gyuk"
        };
    }

}