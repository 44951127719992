import { validate } from 'secure-password-validator'

// Namespace for the validator
export default class Validator {
    static validate(type: string, input: string) {
        switch (type) {
            case "telephone":
                return this.validatePhoneNumber(input);
            case "email":
                return this.validateEmail(input);
            case "name":
                return this.validateName(input);
            case "number":
                return this.validateNumber(input);
            case "password":
                return this.validatePassword(input);
            case "required":
            default:
                return this.validateHasValue(input);
        }
    }

    /**
     * Check whether value is set
     * @param input 
     */
    static validateHasValue(input: string) {
        return !!input.length;
    }

    /**
     * Check whether is a correct number
     * @param input 
     */
    static validateNumber(input: string) {
        return !isNaN(input as any) && !isNaN(parseFloat(input));
    }

    /**
     * Validate name of the user
     * @param input 
     */
    static validateName(input: string) {
        // eslint-disable-next-line 
        const XRegExp = require('xregexp');

        // eslint-disable-next-line
        if (!XRegExp("^[\\pL\- ]+$").test(input)) {
            return false;
        }
        if (input.length>35) {
            return false;
        }

        return true;
    }

    /**
     * Validate password
     * @param input 
     */
    static validatePassword(input: string) {
        return validate(input, {
            minLength: 10,
            digits: true,
            letters: true,
            uppercase: true,
            lowercase: true,
            symbols: true
        }).valid;
    }

    /**
     * Validate email
     * @param input 
     */
    static validateEmail(input: string) {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(input);
    }

    /**
     * Validate phonenumber
     * @param input 
     */
    static validatePhoneNumber(input: string) {
        if (this.validateNumber(input)) {
            return (input.startsWith("+") && input.length === 12) || input.length === 11;
        }
        return false;
    }
}
