import queryString from 'query-string';
import axios from "axios";
import { get } from "lodash";
import type { } from "css-font-loading-module";
import GameHolder from './GameHolder';

type colorOptions = {
    [key: string]: string
}
type customCSS = {
    [key: string]: object
}

/**
 * Setup class
 * This handles loading and usage of elements in the flow
 */
export default class Setup {
    static data = {} as any;
    static market = '';
    static language = 'EN';
    static gameId = 0;
    static gameType = '';
    static colors: colorOptions = {};
    static customCSS: customCSS = {};
    static muted = false as boolean;

    /**
     * Get data from api
     * This loads data from cape and uses it in the platform
     */
    static async getDataFromApi() {
        let parsed = queryString.parse(location.search);

        // Get country and language
        const market = parsed.market;
        const language = parsed.language;
        const gameId = parsed.gameId;

        Setup.market = market ? market : process.env.VUE_APP_GAME_DEFAULT_COUNTRY;
        Setup.language = language ? language : process.env.VUE_APP_GAME_DEFAULT_LANGUAGE;
        Setup.gameId = gameId ? gameId : process.env.VUE_APP_GAME_DEFAULT_ID;

        // Get from storage for refreshes
        if (!parsed.gameId && !parsed.preview && localStorage.getItem('gameData')) {
            parsed = JSON.parse(localStorage.getItem('gameData') as string);
        }
        localStorage.setItem('gameData', JSON.stringify(parsed));

        // Handle a preview link
        if (parsed.preview) {
            Setup.gameId = parseInt(parsed.campaignId as string);

            const result = await axios.post(process.env.VUE_APP_PREVIEW_API + "previews/getCampaign", {
                'campaignId': parsed.campaignId,
                'token': parsed.token,
                'market': parsed.market
            });
            Setup.data = result.data.data.data;
        }
        // Handle regular link
        else {
            // Request JSON data
            let requestUrl = process.env.VUE_APP_GAME_BASEURL + "/" + Setup.gameId + "_" + Setup.market + ".json";

            // On localhost, add non caching parameter to make sure we don't get an old version
            if (window.location.hostname == 'localhost') {
                requestUrl = requestUrl + '?t=' + Date.now();
            }
            const result = await axios.get(requestUrl);
            Setup.data = result.data;
        }

        Setup.gameType = Setup.getValue("settings.gameType");
        Setup.setupPage();
    }

    /**
     * Add tagmanager to the page
     * This loads dynamically if set in the settings of the game. 
     */
    static setupPage() {
        // Set header title
        const headerTitle = Setup.getCopy("meta.title");
        document.title = headerTitle;

        // Load tagmanager
        const tagmanager = Setup.getValue("settings.tagmanager");
        if (tagmanager) {
            (function (w: any, d: any, s: any, l: any, i: any) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js'
                });
                const f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', tagmanager);
        }

        // Colors
        const r: any = document.querySelector(':root');
        r.style.setProperty('--primary-color', Setup.getValue("design.color.primary"));
        r.style.setProperty('--text-color', Setup.getValue("design.text.color"));
        r.style.setProperty('--text-color-headline', Setup.getValue("design.text.headlineColor"));

        // Load fonts
        const fontsToLoad = ['heading', 'bold', 'regular'];
        fontsToLoad.forEach((fontTitle) => {

            // Get fonts from value
            let font_url: string = Setup.getValue("design.fonts." + fontTitle + "[0].url");
            if (!font_url) {
                font_url = Setup.getValue("assets.fonts.bold[0].url");
            }

            const font: any = new FontFace('font_' + fontTitle, 'url(' + font_url + ')');
            font.load().then(function (loaded_face: any) {
                (document as any).fonts.add(loaded_face);
            }).catch(function (error: any) {
                console.log("Error loading font", error);
            });
        });

        // Set custom CSS
        const customCSS = Setup.getValue("design.customCSS.value");
        if (customCSS) {
            try {
                this.customCSS = JSON.parse(customCSS);
            } catch (e) {
                console.error("Error parsing JSON", e);
            }
        }
    }

    /**
     * Get translation from path (to be removed)
     * This fetches the data from the setup. 
     * @param path The path in the original setup data
     * @returns The translation string
     */
    static getCopy(path: string): string {
        let field;

        field = get(Setup.data, "view." + path + "." + Setup.language + ".value");
        if (field) { return field; }

        field = get(Setup.data, "view." + path + ".EN.value");
        if (field) { return field; }

        field = get(Setup.data, "copy." + path + "." + Setup.language + ".value");
        if (field) { return field; }

        field = get(Setup.data, "copy." + path + ".EN.value");
        if (field) { return field; }

        return field;
    }

    /**
     * Get value from setup
     * @param path The path in the original setup data
     * @returns The output
     */
    static getValue(path: string, fallbackValue: any = undefined): any {
        const field = get(Setup.data, path);
        if (!field) {
            return fallbackValue;
        }
        return field;
    }

    /**
     * Get image to use
     * This setup uses three levels:
     * 1) Whether we have it in a custom field set in the settings of a view or model.
     * 2) If not, whether we have it in our custom assets
     * 3) If not, return default of the application
     * @param path The path in the original setup data
     * @returns The output
     */
    static getImageValue(path: string, filename: any = '', type = 'flow'): any {

        // Look at location in the data
        const field = get(Setup.data, path);
        if (field && field[0]) {
            return field[0].url;
        }

        if (field) {
            return field;
        }

        // Look in generic assets
        const assets = get(Setup.data, "assets.files");
        const filenameTitle = filename.substring(0, filename.lastIndexOf("."));

        if (assets && assets[type]) {

            // Loop through objects and items
            const assetObject = assets[type];
            if (Array.isArray(assetObject)) {
                for (const asset of assetObject) {
                    if (asset.title == filenameTitle) {
                        return asset.url;
                    }
                }
            }
        }

        // We have no generic assets option, default to the initial setup of the game framework (e.g. for icons)
        if (type == 'game') {
            return '/images/games/' + Setup.getGameType() + '/' + filename;
        }
        if (filename) {
            return '/images/' + filename;
        }
        return null;

    }

    /**
     * Get file from a list of files by language
     * @param path The path in the original JSON data
     * @returns The file url
     */
    static getFileByLanguage(path: string): string {
        let field = get(Setup.data, path + "." + Setup.language + "[0].url");

        if (!field) {
            field = get(Setup.data, path + "[0].url");
        }
        return field;
    }

    /**
     * Get terms and conditions link
     */
    static getTermsConditionsLink(): string {
        if (Setup.getCopy('termsConditions.link')) {
            return Setup.getCopy('termsConditions.link');
        }
        return Setup.getFileByLanguage('copy.files.termsConditions');
    }

    /**
     * Get privacy policy link
     */
    static getPrivacyPolicyLink(): string {
        if (Setup.getCopy('privacyPolicy.link')) {
            return Setup.getCopy('privacyPolicy.link');
        }
        return Setup.getFileByLanguage('copy.files.privacyPolicy');
    }

    /**
     * Get current language
     * @returns Language that is set
     */
    static getLanguage(): string {
        return Setup.language;
    }

    /**
     * Get current gameId
     * @returns game id
     */
    static getGameId(): number {
        return Setup.gameId;
    }

    /**
     * Get current game type
     * @returns game type
     */
    static getGameType(): string {
        return Setup.gameType;
    }

    /**
     * Set language
     * @param language The language code
     */
    static setLanguage(language: string) {
        Setup.language = language;
    }

    /**
     * Get color
     * @param type The type
     */
    static getColor(type: string): string {
        const color = Setup.getValue("design.color." + type);
        if (!color) {
            return Setup.getValue("design.color.primary");
        }

        return color;
    }

    /**
     * Get value from setup
     * @param path The path in the original setup data
     * @returns The output
     */
    static getCustomCSS(type: string): object {
        if (Setup.customCSS && Setup.customCSS[type]) {
            return Setup.customCSS[type];
        }
        return {};
    }

    /**
     * Get image from local storage or remote url
     * @param filename The filename of the url
     * @param type The type of the item (flow or game)
     * @returns The file url
     */
    static getImage(filename: string, type = 'flow'): string {
        const assets = get(Setup.data, "assets.files");
        const filenameTitle = filename.substring(0, filename.lastIndexOf("."));

        if (assets && assets[type]) {

            // Loop through objects and items
            const assetObject = assets[type];
            if (Array.isArray(assetObject)) {
                for (const asset of assetObject) {
                    if (asset.title == filenameTitle) {
                        return asset.url;
                    }
                }
            }
        }

        if (type == 'game') {
            return '/images/games/' + Setup.getGameType() + '/' + filename;
        }

        return '/images/' + filename;
    }

    /**
     * Get sound from local storage or remote url
     * @param filename The filename of the url
     * @param type The type of the item (flow or game)
     * @returns The file url
     */
    static getSound(filename: string): string {
        const assets = get(Setup.data, "assets.files");
        const filenameTitle = filename.substring(0, filename.lastIndexOf("."));

        if (assets && assets.game) {

            // Loop through objects and items
            const assetObject = assets.game;
            if (Array.isArray(assetObject)) {
                for (const asset of assetObject) {
                    if (asset.title == filenameTitle) {
                        return asset.url;
                    }
                }
            }
        }

        return '/sounds/games/' + Setup.getGameType() + '/' + filename;       
    }

    /**
     * Check whether the item is muted
     */
    static isMuted(): boolean {
        return this.muted;
    }

    /**
     * Set muted value
     * @param mute 
     */
    static setMuted(mute: boolean) {
        this.muted = mute;
        GameHolder.setMuted(mute)
    }

    /**
     * Return whether the view is included
     * @param view 
     */
    static includesView(view: string) {
        const views: string[] = Setup.getValue('settings.flow.views.value', [])
        return views.includes(view);
    }

}